.br, .br-bottom
	margin-bottom: 15px

	&-xs
		margin-top: 5px
		
	&-md
		margin-bottom: 30px

	&-lg
		margin-bottom: 45px

.br-top
	margin-top: 15px

	&-xs
		margin-top: 5px

	&-md
		margin-top: 30px

	&-lg
		margin-top: 45px

.br-left
	margin-left: 15px

	&-xs
		margin-left: 5px

	&-md
		margin-left: 30px

	&-lg
		margin-left: 45px

.br-right
	margin-right: 15px

	&-xs
		margin-right: 5px

	&-md
		margin-right: 30px

	&-lg
		margin-right: 45px
