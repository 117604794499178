// Imports
@import "lib/colors"

// Checkbox and Radio
// Basic control styles
.control
  display block
  position relative
  padding-left 25px
  margin-bottom 6px
  cursor pointer
  color $gray--dark

  // Hide default browser input
  input
    position absolute
    z-index -1
    opacity 0

// Custom control
.control__indicator
  position absolute
  top 2px
  left 0
  height 18px
  width 18px
  background $gray--light
  border-radius 2px

  .control--radio &
    border-radius 50% // Makes radio buttons circlular

  // Hover and focus
  .control:hover input ~ &,
  .control input:focus ~ &
    background $gray

  // Checked
  .control input:checked ~ &
    background $blue

  // Hover when checked
  .control:hover input:not([disabled]):checked ~ &,
  .control input:checked:focus ~ &
    background $blue - 10%

  // Hide default browser input
  .control input:disabled ~ &
    background $gray--light
    opacity 0.6
    pointer-events none

  &:after
    content ''
    position absolute
    display none // Hide check

    .control input:checked ~ &
      display block // Show check

    // Checkbox tick
    .control--checkbox &
      left 8px
      top 4px
      width 3px
      height 8px
      border solid $white
      border-width 0 2px 2px 0
      transform rotate(45deg)

    // Disabled tick colour
    .control--checkbox input:disabled ~ &
      border-color $gray--dark

    // Radio button inner circle
    .control--radio &
      left: 6px
      top: 6px
      height: 6px
      width: 6px
      border-radius: 50%
      background: $white

    // Disabled circle colour
    .control--radio input:disabled ~ &
      background: $gray--dark

.form-control-sm
	width: 120px

.form-control-inhrerit
	width: inherit

.form-control-line
	border: none
	border-bottom: 1px solid #aaa
	outline: none
// https://kyusuf.com/post/completely-css-custom-checkbox-radio-buttons-and-select-boxes

.callback
	display: none
	font-size: 13px
	font-weight: 600
	color: $red
