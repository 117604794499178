// Flexbox
.flex
  display: flex
  .pad-15
    padding-right: 15px
  .pad-10
    padding-right: 10px;
.flex-column
  flex-direction: column
.flex-row
  flex-direction: row

.center
  align-items: center
  justify-content: center
.center-left
  align-items: center
  justify-content: flex-start
.center-right
  align-items: center
  justify-content: flex-end

.self-top, .self-top .box
  align-self: flex-start
.self-button
  align-self: flex-end

.full-v
  align-items: stretch

.grow
  flex-grow: 1
.grow-2x
  flex-grow: 2

.wrap
  flex-wrap: wrap

.flex-inline
  display: inline-flex
  align-items: center

  &.flex-align--start
    align-items: flex-start;

  .flex-item
    padding-right: 15px
