.container-map
  width: 100%
  height: calc(100% - 120px)
  background-color: $light
  position: relative
  overflow-x: hidden
  #map
    width: 100%
    height: 100%

  .map-filters
    position: absolute
    height: auto
    width: 240px
    top: 20px
    right: 0px
    border-radius: 0 0 0 4px
    background-color: $white
    transition: all 1s ease
    z-index: 9
    border-color: #e5e5e5
    border-width: 1px 0px 1px 1px
    border-style: solid

    .filter__arrow
      font-size: 25px
      color: $gray--dark
      position: absolute
      top: -1px
      left: -35px
      width: 35px
      height: 35px
      background: $white
      display: flex
      align-items: center
      justify-content: center
      border-radius: 4px 0 0 4px
      cursor: pointer
      border-color: $boder
      border-width: 1px 0px 1px 1px
      border-style: solid

    .filter-header
      padding: 7px 12px
    .filter-body
      padding: 15px 12px
      hr
        padding: 0px
        margin: 5px 0

      .filter__label
        font-size: 12px
        margin-top: 4px
        color: $dark--light
      .form-group
        margin-bottom: 0

      .sw__left
        text-align: right

  .map-filters.right
    transform: translateX(240px)
    transition: all 1s ease

// Infowindow
.iw--residence, .iw--place
  min-width: 180px
  overflow-y: auto
  div
    margin-top: 2px
  .iw__id
    font-family: $font-semibold
    font-size: 14px
    color: $blue
    cursor: pointer
    &:hover
      color: $blue - 20%
  i
    font-weight: bold
    margin-right: 10px
    color: $gray
  i.green
    color: $green

.iw--img
  position: fixed
  height: 233px
  width: 233px
  background: $gray url('../../../img/photo.png') no-repeat center
  margin-top: -241px
  margin-left: -15px
  img
    border-radius: 3px 3px 0 0

.iw--icon
  padding: 4px
  position: absolute
  right: 0
  bottom: 0
  margin: 10px
  background: rgba(0,0,0,0.2)
  border-radius: 4px
  color: $white
  cursor: pointer
  &:hover
    background: rgba(0,0,0,0.4)


.gm-style-iw
  + div
    top: 12px !important

.flex.places
  margin-bottom: 6px
.badge.distance
  background: $lighten
  color: $black - 20%
  padding: 4px 7px
  border-radius: 11px

// Audio
.interview--audio
  // width: 450px
  margin-bottom: 15px
	&.interview--audio-mb-0
		margin-bottom: 0px
.researches
	padding: 10px 0 20px
	.text-sm
		font-size: 0.85em
	.flex
		margin-bottom: 10px
		padding-bottom: 5px
		&:not(:last-child)
			border-bottom: 1px solid #eee
	.flex-item
		padding-right: 15px

.radius
	border-radius: 3px

// Interviews
.notes
  font-size: 24px
  color: $gray
  margin-top: 10px
.well
  font-size: 13px
  color: $dark

// For switch
.pg
  padding: 10px 0 0

#list-details
  width: 90%;
  min-height: 98%;
  padding: 30px;
  background-color: $white
  margin: 5px auto;

.map--legends
  padding-top: 0px
  font-size: 0.8em
  > p
    margin: 2px 4px 0 0px
  ul
    list-style-type: none
    padding: 0 0 0 8px
    li
      margin-bottom: 4px
      img
        width: 20px
        margin-right: 10px
      p
        margin: 0
        color: $dark--light

.list-report-residences
  margin-bottom: 15px

#mReports {
  padding: 15px
  width: 98%
  margin: 15px auto
}

#group-details
  height: 100%
