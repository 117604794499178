// Default
$black = #333
$white = #fff
$dark = #555
$light = #eaeaea
$lighten = #efefef
$dark--light = #777
$color--green = #5cb85c
$green = #60b36a
$clear = #f2f2f2
$boder = #e5e5e5

// Gradient
$bg     = #4bae4f
$bg--dark = #2f7e32

// Checkbox / Radio
$white       = #FFF
$blue        = #2AA1C0
$gray        = #CCC
$gray--light = #E6E6E6
$gray--dark  = #7B7B7B
$red         = #a41e1e

.bg__dark
  background-color: $dark - 10%
  color: $white
  .form-control
    &:focus
      border-color: $dark--light
      box-shadow: none
  &:hover
    background-color: $dark + 10%
    transition: 0.2s ease-in

// Icons
.icon-muted
  color: $light - 10%

.table-hover tr,
.list-group li
  &:hover
    .icon-muted
    	color: $dark--light

.color--blue
  color: $blue

.bg--white
  background: $white
