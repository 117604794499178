// Fonts
@font-face
  font-family: 'Open Sans'
  font-style: normal
  font-weight: 400
  src: local('Open Sans'),
       local('OpenSans'), url('/assets/fonts/OpenSans-Regular.ttf') format('truetype')

@font-face
  font-family: 'Open Sans Semibold'
  font-style: normal
  font-weight: 400
  src: local('Open Sans Semibold'),
       local('OpenSansSemibold'), url('/assets/fonts/OpenSans-Semibold.ttf') format('truetype')

// Variables
$font-regular = 'Open Sans', sans-serif
$font-semibold = 'Open Sans Semibold', sans-serif
