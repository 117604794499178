// Imports
@import "lib/colors"
@import "lib/fonts"
@import "lib/mixins"
@import "lib/forms"
@import "lib/flexbox"
@import "lib/alert"
@import "lib/utils"
@import "lib/layout"
@import "lib/panels"
@import "lib/icons"
@import "map"

// Main
html,body
  height: 100%

body
  font-family: $font-regular

.menu--v
  background-color: $dark
  .brand
    border-bottom: 1px solid $black
		img
			width: 100%
			max-width: 250px
  .side-menu .side-item
    &:hover
      background-color: $dark - 20%
      transition: 0.25s ease-in
    ^[1].active
      background-color: $black

    font-size: 12px
    text-transform: uppercase
    padding: 20px 15px

.login-bg
  gradient(-45deg, $bg--dark, $bg)

@media (max-height: 360px)
  html,body
    height: auto

.login-block
	width: 100%
	max-width: 400px
	margin: 15px
	.logo-fiocruz
		.bg-logo
			text-align: center
			padding: 10px
			margin: 0 auto
			border-radius: 4px
			img
				width: 100%
				max-width: 220px
			p
				color: $white
				margin: 5px 0 0

.login.card
  border-radius: 4px
  border: 0px
  width: 100%
  max-width: 400px
  padding: 10px
  // margin: 15px
  h4
    padding: 5px 0 0
  label
    font-weight: normal
  button
    padding: 8px 12px
  p
    color: $dark--light
	.logo
		img
			width: 100%
			max-width: 250px
			margin-bottom: 20px

.recover.card
  border-radius: 4px
  label
    font-weight: normal
  span
    font-size: 11px

.badge
  font-weight: normal
  color: $dark
  ^[0].badge-md
    border-radius: 4px

// Lists
.list-group
  > .list-item
    &.active
      background-color: $dark
      border-radius: 4px
      .icon-muted
        &:hover
          color: $light - 10%

// Status
.switch--group-status
  justify-content: flex-end
  .group-status
    margin-right: 10px
  .box
    height: 20px
    span
      color: $gray--dark
      font-weight: normal

// Margins
.mLeft
    margin-right: 10px
.mBotton
  margin-bottom: 10px

// Buttons
.btn__lg
  min-width: 110px

// Icons
.delete.material-icons
  font-size: 22px

// Card groups
.group
  .card__name
    font-size: 16px


// Types of place
.places--wrap
  label
    font-weight: normal
    text-transform: uppercase
    font-size: 14px
  .place__send
    padding-bottom: 20px
    .btn-min
      min-width: 120px
      margin-left: 15px

  i
    margin: 5px 0 5px 10px
    cursor: pointer
    color: $gray--light
  .places--list
    li
      .place__types
        padding: 5px 0
        &:focus
          outline: none
      .place__types.edit
        background-color: $clear
        color: $dark
        border-radius: 4px
        padding-left: 10px
        padding-top: 6px
      button
        display: none
        margin: 0 15px
      &:hover
        i
          color: $dark

// Researchers
.form-researchers
  .form-group
    margin-top: 10px
    label
      font-weight: normal
      color: $dark
    button
      margin-top: 23px

.panel-question
	.edit
		position: absolute
		top: 15px
		right: 45px
	.delete
		position: absolute
		top: 15px
		right: 15px

// Qtv answer
.qltv--answers
	span
		cursor: pointer
		color: $dark--light
		&:hover
			text-decoration: underline
			color: $black - 20%

.btn--icons
	> i
		font-size: 26px
		color: $gray
		align-self: center
		padding-left: 10px
		&:hover
			color: $dark

	.group-name-done
		margin-left: 10px
		align-self: center
