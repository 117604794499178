.bg__dark {
  background-color: #4d4d4d;
  color: #fff;
}
.bg__dark .form-control:focus {
  border-color: #777;
  box-shadow: none;
}
.bg__dark:hover {
  background-color: #666;
  transition: 0.2s ease-in;
}
.icon-muted {
  color: #d3d3d3;
}
.table-hover tr:hover .icon-muted,
.list-group li:hover .icon-muted {
  color: #777;
}
.color--blue {
  color: #2aa1c0;
}
.bg--white {
  background: #fff;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("/assets/fonts/OpenSans-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Semibold'), local('OpenSansSemibold'), url("/assets/fonts/OpenSans-Semibold.ttf") format('truetype');
}
.bg__dark {
  background-color: #4d4d4d;
  color: #fff;
}
.bg__dark .form-control:focus {
  border-color: #777;
  box-shadow: none;
}
.bg__dark:hover {
  background-color: #666;
  transition: 0.2s ease-in;
}
.icon-muted {
  color: #d3d3d3;
}
.table-hover tr:hover .icon-muted,
.list-group li:hover .icon-muted {
  color: #777;
}
.color--blue {
  color: #2aa1c0;
}
.bg--white {
  background: #fff;
}
.control {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #7b7b7b;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background: #e6e6e6;
  border-radius: 2px;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #2aa1c0;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #2691ad;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 6px;
  top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.form-control-sm {
  width: 120px;
}
.form-control-inhrerit {
  width: inherit;
}
.form-control-line {
  border: none;
  border-bottom: 1px solid #aaa;
  outline: none;
}
.callback {
  display: none;
  font-size: 13px;
  font-weight: 600;
  color: #a41e1e;
}
.flex {
  display: flex;
}
.flex .pad-15 {
  padding-right: 15px;
}
.flex .pad-10 {
  padding-right: 10px;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.center-left {
  align-items: center;
  justify-content: flex-start;
}
.center-right {
  align-items: center;
  justify-content: flex-end;
}
.self-top,
.self-top .box {
  align-self: flex-start;
}
.self-button {
  align-self: flex-end;
}
.full-v {
  align-items: stretch;
}
.grow {
  flex-grow: 1;
}
.grow-2x {
  flex-grow: 2;
}
.wrap {
  flex-wrap: wrap;
}
.flex-inline {
  display: inline-flex;
  align-items: center;
}
.flex-inline.flex-align--start {
  align-items: flex-start;
}
.flex-inline .flex-item {
  padding-right: 15px;
}
.alert {
  padding: 15px;
  border-radius: 4px;
  width: 100%;
  margin: 15px 0;
}
.alert.alert--success {
  background-color: #ceeace;
  color: #3b873b;
}
.ck {
  cursor: pointer;
}
.list-group {
  margin-bottom: 30px;
}
.modal {
  overflow-y: auto !important;
}
.br,
.br-bottom {
  margin-bottom: 15px;
}
.br-xs,
.br-bottom-xs {
  margin-top: 5px;
}
.br-md,
.br-bottom-md {
  margin-bottom: 30px;
}
.br-lg,
.br-bottom-lg {
  margin-bottom: 45px;
}
.br-top {
  margin-top: 15px;
}
.br-top-xs {
  margin-top: 5px;
}
.br-top-md {
  margin-top: 30px;
}
.br-top-lg {
  margin-top: 45px;
}
.br-left {
  margin-left: 15px;
}
.br-left-xs {
  margin-left: 5px;
}
.br-left-md {
  margin-left: 30px;
}
.br-left-lg {
  margin-left: 45px;
}
.br-right {
  margin-right: 15px;
}
.br-right-xs {
  margin-right: 5px;
}
.br-right-md {
  margin-right: 30px;
}
.br-right-lg {
  margin-right: 45px;
}
.panel {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 15px;
  position: relative;
}
.icon-md {
  font-size: 28px;
}
.icon-dark {
  color: #555;
}
.container-map {
  width: 100%;
  height: calc(100% - 120px);
  background-color: #eaeaea;
  position: relative;
  overflow-x: hidden;
}
.container-map #map {
  width: 100%;
  height: 100%;
}
.container-map .map-filters {
  position: absolute;
  height: auto;
  width: 240px;
  top: 20px;
  right: 0px;
  border-radius: 0 0 0 4px;
  background-color: #fff;
  transition: all 1s ease;
  z-index: 9;
  border-color: #e5e5e5;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
}
.container-map .map-filters .filter__arrow {
  font-size: 25px;
  color: #7b7b7b;
  position: absolute;
  top: -1px;
  left: -35px;
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  border-color: #e5e5e5;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
}
.container-map .map-filters .filter-header {
  padding: 7px 12px;
}
.container-map .map-filters .filter-body {
  padding: 15px 12px;
}
.container-map .map-filters .filter-body hr {
  padding: 0px;
  margin: 5px 0;
}
.container-map .map-filters .filter-body .filter__label {
  font-size: 12px;
  margin-top: 4px;
  color: #777;
}
.container-map .map-filters .filter-body .form-group {
  margin-bottom: 0;
}
.container-map .map-filters .filter-body .sw__left {
  text-align: right;
}
.container-map .map-filters.right {
  transform: translateX(240px);
  transition: all 1s ease;
}
.iw--residence,
.iw--place {
  min-width: 180px;
  overflow-y: auto;
}
.iw--residence div,
.iw--place div {
  margin-top: 2px;
}
.iw--residence .iw__id,
.iw--place .iw__id {
  font-family: 'Open Sans Semibold', sans-serif;
  font-size: 14px;
  color: #2aa1c0;
  cursor: pointer;
}
.iw--residence .iw__id:hover,
.iw--place .iw__id:hover {
  color: #22819a;
}
.iw--residence i,
.iw--place i {
  font-weight: bold;
  margin-right: 10px;
  color: #ccc;
}
.iw--residence i.green,
.iw--place i.green {
  color: #60b36a;
}
.iw--img {
  position: fixed;
  height: 233px;
  width: 233px;
  background: #ccc url("../../../img/photo.png") no-repeat center;
  margin-top: -241px;
  margin-left: -15px;
}
.iw--img img {
  border-radius: 3px 3px 0 0;
}
.iw--icon {
  padding: 4px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 10px;
  background: rgba(0,0,0,0.2);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.iw--icon:hover {
  background: rgba(0,0,0,0.4);
}
.gm-style-iw + div {
  top: 12px !important;
}
.flex.places {
  margin-bottom: 6px;
}
.badge.distance {
  background: #efefef;
  color: #292929;
  padding: 4px 7px;
  border-radius: 11px;
}
.interview--audio {
  margin-bottom: 15px;
}
.interview--audio-mb-0 {
  margin-bottom: 0px;
}
.researches {
  padding: 10px 0 20px;
}
.researches .text-sm {
  font-size: 0.85em;
}
.researches .flex {
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.researches .flex:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.researches .flex-item {
  padding-right: 15px;
}
.radius {
  border-radius: 3px;
}
.notes {
  font-size: 24px;
  color: #ccc;
  margin-top: 10px;
}
.well {
  font-size: 13px;
  color: #555;
}
.pg {
  padding: 10px 0 0;
}
#list-details {
  width: 90%;
  min-height: 98%;
  padding: 30px;
  background-color: #fff;
  margin: 5px auto;
}
.map--legends {
  padding-top: 0px;
  font-size: 0.8em;
}
.map--legends > p {
  margin: 2px 4px 0 0px;
}
.map--legends ul {
  list-style-type: none;
  padding: 0 0 0 8px;
}
.map--legends ul li {
  margin-bottom: 4px;
}
.map--legends ul li img {
  width: 20px;
  margin-right: 10px;
}
.map--legends ul li p {
  margin: 0;
  color: #777;
}
.list-report-residences {
  margin-bottom: 15px;
}
#mReports {
  padding: 15px;
  width: 98%;
  margin: 15px auto;
}
#group-details {
  height: 100%;
}
html,
body {
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
}
.menu--v {
  background-color: #555;
}
.menu--v .brand {
  border-bottom: 1px solid #333;
}
.menu--v img {
  width: 100%;
  max-width: 250px;
}
.menu--v .side-menu .side-item {
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 15px;
}
.menu--v .side-menu .side-item:hover {
  background-color: #444;
  transition: 0.25s ease-in;
}
.menu--v .side-menu .side-item.active {
  background-color: #333;
}
.login-bg {
  background-color: #2f7e32;
  background-image: linear-gradient(-45deg, #2f7e32 0%, #4bae4f 100%);
}
@media (max-height: 360px) {
  html,
  body {
    height: auto;
  }
}
.login-block {
  width: 100%;
  max-width: 400px;
  margin: 15px;
}
.login-block .logo-fiocruz .bg-logo {
  text-align: center;
  padding: 10px;
  margin: 0 auto;
  border-radius: 4px;
}
.login-block .logo-fiocruz .bg-logo img {
  width: 100%;
  max-width: 220px;
}
.login-block .logo-fiocruz .bg-logo p {
  color: #fff;
  margin: 5px 0 0;
}
.login.card {
  border-radius: 4px;
  border: 0px;
  width: 100%;
  max-width: 400px;
  padding: 10px;
}
.login.card h4 {
  padding: 5px 0 0;
}
.login.card label {
  font-weight: normal;
}
.login.card button {
  padding: 8px 12px;
}
.login.card p {
  color: #777;
}
.logo img {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}
.recover.card {
  border-radius: 4px;
}
.recover.card label {
  font-weight: normal;
}
.recover.card span {
  font-size: 11px;
}
.badge {
  font-weight: normal;
  color: #555;
}
.badge.badge-md {
  border-radius: 4px;
}
.list-group > .list-item.active {
  background-color: #555;
  border-radius: 4px;
}
.list-group > .list-item.active .icon-muted:hover {
  color: #d3d3d3;
}
.switch--group-status {
  justify-content: flex-end;
}
.switch--group-status .group-status {
  margin-right: 10px;
}
.switch--group-status .box {
  height: 20px;
}
.switch--group-status .box span {
  color: #7b7b7b;
  font-weight: normal;
}
.mLeft {
  margin-right: 10px;
}
.mBotton {
  margin-bottom: 10px;
}
.btn__lg {
  min-width: 110px;
}
.delete.material-icons {
  font-size: 22px;
}
.group .card__name {
  font-size: 16px;
}
.places--wrap label {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
}
.places--wrap .place__send {
  padding-bottom: 20px;
}
.places--wrap .place__send .btn-min {
  min-width: 120px;
  margin-left: 15px;
}
.places--wrap i {
  margin: 5px 0 5px 10px;
  cursor: pointer;
  color: #e6e6e6;
}
.places--wrap .places--list li .place__types {
  padding: 5px 0;
}
.places--wrap .places--list li .place__types:focus {
  outline: none;
}
.places--wrap .places--list li .place__types.edit {
  background-color: #f2f2f2;
  color: #555;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 6px;
}
.places--wrap .places--list li button {
  display: none;
  margin: 0 15px;
}
.places--wrap .places--list li:hover i {
  color: #555;
}
.form-researchers .form-group {
  margin-top: 10px;
}
.form-researchers .form-group label {
  font-weight: normal;
  color: #555;
}
.form-researchers .form-group button {
  margin-top: 23px;
}
.panel-question .edit {
  position: absolute;
  top: 15px;
  right: 45px;
}
.panel-question .delete {
  position: absolute;
  top: 15px;
  right: 15px;
}
.qltv--answers span {
  cursor: pointer;
  color: #777;
}
.qltv--answers span:hover {
  text-decoration: underline;
  color: #292929;
}
.btn--icons > i {
  font-size: 26px;
  color: #ccc;
  align-self: center;
  padding-left: 10px;
}
.btn--icons > i:hover {
  color: #555;
}
.btn--icons .group-name-done {
  margin-left: 10px;
  align-self: center;
}
